import React from 'react'
import {
  PaginaDocument,
  ProdottiPreventivatoreDocument,
} from '@next-nx/shared-graphql/assicurazioni'
import { initializeApollo } from '../client'
import { HomeTemplate } from '@next-nx/shared-ui/templates'
import { NextWrapper } from '../data-fetching-wrapper'

const Homepage = ({ pagina, prodottiPreventivatore }) => {
  return <HomeTemplate pagina={pagina} prodottiPreventivatore={prodottiPreventivatore} />
}

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  const client = initializeApollo()

  const {
    data: { pagina },
  } = await client.query({
    query: PaginaDocument,
    variables: {
      chiave: 'homepage',
    },
  })

  if (!pagina) {
    return {
      notFound: true,
    }
  }

  const { data: prodottiPreventivatoreData } = await client.query({
    query: ProdottiPreventivatoreDocument,
  })
  const prodottiPreventivatore =
    prodottiPreventivatoreData?.prodottiPreventivatore?.edges.map((x) => x.node) || []

  return {
    props: { pagina, prodottiPreventivatore }, // will be passed to the page component as props
    revalidate: 60 * 60, // In seconds
  }
})

export default Homepage
